import React from 'react'

export default function Hinnasto() {
  return (

    <div class="hinnastosivu">
      <div class="hinnat">
        <h1>Hinnastot</h1>

        <table id="hierontatable">
          <h2>Hieronnat</h2>
          <h3>Klassinen hieronta</h3>
          <tr>
            <th>Hieronnan pituus</th>
            <th >Hinta</th>
          </tr>
          <tr>
            <td>45 min</td>
            <td>40 €</td>
          </tr>
          <tr>
            <td>60 min</td>
            <td>50 €</td>
          </tr>
          <tr>
            <td>75 min</td>
            <td>65 €</td>
          </tr>
          <tr>
            <td>90 min</td>
            <td>75 €</td>
          </tr>

        </table>
        <br />
        <table id="hierontatable">
          <h3>Urheiluhieronta</h3>
          <tr>
            <th>Hieronnan pituus</th>
            <th >Hinta</th>
          </tr>
          <tr>
            <td>45 min</td>
            <td>42 €</td>
          </tr>
          <tr>
            <td>60 min</td>
            <td>55 €</td>
          </tr>
          <tr>
            <td>75 min</td>
            <td>70 €</td>
          </tr>
          <td>90 min</td>
          <td>80 €</td>
        </table>
        <br />
        <table id="hierontatable">
          <h3>Raskausajan hieronta</h3>
          <tr>
            <th>Hieronnan pituus</th>
            <th >Hinta</th>
          </tr>
          <tr>
            <td>45 min</td>
            <td>42 €</td>
          </tr>
          <tr>
            <td>60 min</td>
            <td>55 €</td>
          </tr>
          <tr>
            <td>75 min</td>
            <td>68 €</td>
          </tr>
          <tr>
            <td>90 min</td>
            <td>80 €</td>
          </tr>
        </table>
        <p id="hinta">Muut ajat (kello 20-06) +10 € varattava sähköpostilla tai puhelimella</p>
        <p id="hinta">Hieronta kotona/työpaikalla (matkapöydällä) alk. +10 € (lisähinta ajettavien kilometrien mukaan)</p>
        <br />
        <table id="sarjatable">
          <h2>Sarjakortit hierontaan</h2>
          <tr>
            <th>Kertojen määrä</th>
            <th>Hinta</th>
          </tr>
          <tr>
            <td>3 x 45 min</td>
            <td>130 €</td>
          </tr>
          <tr>
            <td>3 x 60 min</td>
            <td>145 €</td>
          </tr>
          <tr>
            <td>3 x 90 min</td>
            <td>220 €</td>
          </tr>
          <br />
          <tr>
            <td>5 x 45 min</td>
            <td>220 €</td>
          </tr>
          <tr>
            <td>5 x 60 min</td>
            <td>245 €</td>
          </tr>
          <tr>
            <td>5 x 90 min</td>
            <td>370 €</td>
          </tr>
          <br />
          <tr>
            <td>10 x 45 min</td>
            <td>435 €</td>
          </tr>
          <tr>
            <td>10 x 60 min</td>
            <td>485 €</td>
          </tr>
          <tr>
            <td>10 x 90 min</td>
            <td>735 €</td>
          </tr>
        </table>
        <br />
        <table id="pttable">
          <h2>Personal Trainer Hinnasto</h2>
          <tr>
            <th>Kertavalmennus</th>
            <th>Hinta</th>
          </tr>
          <tr>
            <td>1 krt</td>
            <td>80 €</td>
          </tr>
          <tr>
            <td>3 krt</td>
            <td>225 €</td>
          </tr>
          <tr>
            <td>5 krt</td>
            <td>349 €</td>
          </tr>
          <tr>
            <td>7 krt</td>
            <td>489 €</td>
          </tr>
          <tr>
            <td>9 krt</td>
            <td>629 €</td>
          </tr>
          <tr>
            <td>15 krt</td>
            <td>945 €</td>
          </tr>
          <tr>
            <td>20 krt</td>
            <td>1220 €</td>
          </tr>
          <tr>
            <td>Ravintosuunnitelma</td>
            <td>160 €</td>
          </tr>
          <br />
          <tr>
            <th>Kuukausivalmennus</th>
            <th>Hinta</th>
          </tr>
          <tr>
            <td>Ensimmäinen kuukausi (ei sisällä ravitsemussuunnitelmaa)</td>
            <td>199 €</td>
          </tr>
          <tr>
            <td>Ensimmäinen kuukausi (sis. ravintosuunnitelman)</td>
            <td>280 €</td>
          </tr>
          <tr>
            <td>Ensimmäinen kuukausi (sis. ravintosuunnitelman ja 60min klassisen/raskausajan hieronnan)</td>
            <td>300 €</td>
          </tr>
          <br />
          <tr>
            <td>Seuraavat kuukaudet, sis. 1 tapaaminen valmentajan kanssa jokaista kuukautta kohden (12kk sisään aloituksesta)</td>
            <td>80€</td>
          </tr>
          <tr>
            <td>Seuraavat kuukaudet, sis. 2 tapaamista valmentajan kanssa jokaista kuukautta kohden (12kk sisään aloituksesta)</td>
            <td>150 €</td>
          </tr>
        </table>
      </div>
    </div>
  )
}